import React, { useRef,useState ,useEffect} from 'react';
import { Container, Row, Col ,Button,Card,Accordion,Image ,Navbar, Nav,Alert ,Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { FaRegLightbulb } from "react-icons/fa";
import { PiTarget } from "react-icons/pi";
import { TbMessages } from "react-icons/tb";
import BackImg from "../src/assests/images/sss.avif";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineMailOpen } from "react-icons/hi";
import { PiSpeakerSimpleHigh } from "react-icons/pi";
import { motion ,useInView } from 'framer-motion';
import QatarAirways from '../src/assests/images/qatar.jpg';
import AirIndia from '../src/assests/images/airindia.jpg';
import Amazon from '../src/assests/images/amazon.png';
import Surfshark from '../src/assests/images/surfshark.png';
import Wakefit from '../src/assests/images/wakefit.webp';
import Cleartrip from '../src/assests/images/cleartrip.png';
import Indigo from '../src/assests/images/indigo.png';
import Conforma from '../src/assests/images/conforma.png'
import Contact from "../src/assests/images/best-contact-us.png"
import './App.css';

function App() {
  const approaches = [
    {
      icon: '📖', // Placeholder for an actual icon
      title: 'Strategy',
      description: 'We have a large team of enthusiasts who invest themselves into your brand and conjure up the best ideas and strategies. We always put your brand first.'
    },
    {
      icon: '📊',
      title: 'Drive Brand Building',
      description: 'Handholding a brand into recognition is one of our fortes. We ensure to enhance and bring forth your brand into its deserved limelight by getting it into the attention of the right audience.'
    },
    {
      icon: '🧠',
      title: 'Analyses & Innovation',
      description: 'Knowing the audience is the foremost step for any advertiser. Our professionals present the best permutations of audiences for your brand. We innovate to connect with them.'
    },
    {
      icon: '📢',
      title: 'Launch',
      description: 'Trust us to handpick the best mix of inventories to kickstart any new launches to generate maximum results.'
    },
    {
      icon: '🌐',
      title: 'Quality Traffic',
      description: 'Focusing on quality, we have an eye for audiences. You receive superlative traffic from relevant sections of the population you want to target.'
    },
    {
      icon: '🎯',
      title: 'Conversions',
      description: 'At the end of the day, it’s all about ROI. We recognize the value of conversions along with quality traffic, which domino-effects into results.'
    }
  ];
  const services = [
    {
      icon: <MdOutlineDashboardCustomize/>,  // Placeholder for your icon (use your preferred icon set)
      title: 'Media Buying',
      description: 'We use the best Native ad platforms and programmatic ad buying across DSPs for your ads to always keep your brand in the spotlight.',
    },
    {
      icon: <FaRegLightbulb/>,
      title: 'Affiliate Marketing',
      description: 'Coercing performance through the best and exclusive affiliate partnerships.',
    },
    {
      icon: <PiTarget/>,
      title: 'Search Engine Marketing',
      description: 'Ensuring that your brand is always in view for your targeted customers.',
    },
    {
      icon: <TbMessages/>,
      title: 'Content & Social Media Marketing',
      description: 'Crafting and disseminating interesting content about the brand and using social media networks to reach the right users to foster their interest in your brand.',
    },
  ];
  
  const clients = [
    { name: 'QatarAirways', logo: QatarAirways },
    { name: 'AirIndia', logo: AirIndia },
    { name: 'Amazon', logo: Amazon },
    { name: 'Surfshark', logo: Surfshark },
    { name: 'Wakefit', logo: Wakefit },
    { name: 'Cleartrip', logo: Cleartrip },
    { name: 'ADPUMP', logo: Indigo },
    { name: 'BLANJA', logo: Conforma },
   

  ];
  const steps = [
    {
      number: '01',
      title: 'Expectation Setting',
      description:
        'We have a team that is adept at understanding your requirements and expectations and can offer strategic insights specific to you.',
    },
    {
      number: '02',
      title: 'Research',
      description:
        'We understand your target audience. How? We are constantly and incessantly upgrading our knowledge base about the markets and their dynamism through research.',
    },
    {
      number: '03',
      title: 'Strategize',
      description:
        'We come up with the best approach to meet our clients’ goals and expectations. Our strategies are unique and customized for each client.',
    },
    {
      number: '04',
      title: 'Result Oriented',
      description:
        'We deliver results and are proud to have accelerated many a brand to their desired results.',
    },
  ];
  const steps2 = [
    {
      number: <CiLocationOn/>,
      title: 'VISIT OUR OFFICE',
      description:
        'Sharjah, United Arab Emirates',
    },
    {
      number: <TbMessages/>,
      title: "LET'S TALK",
      description:
        '+91 7608 892 920',
    },
    {
      number: <HiOutlineMailOpen/>,
      title: "LET'S TALK",
      description:
        'Abdulla@hike-media.com',
    },
    {
      number: <PiSpeakerSimpleHigh/>,
      title: 'FOR INSTANT MARKETING',
      description:
        '+91 7608 892 920',
    },
  ];
  const fadeInVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const Section = ({ children, customDelay }) => {
    const ref = useRef(null);
    const inView = useInView(ref, { once: true });
   
    return (
      <motion.div
        ref={ref}
        variants={fadeInVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        custom={customDelay}
      >
        {children}
      </motion.div>
    );
  };
  const headerRef = useRef(null);
  const [bgColor, setBgColor] = useState('black');

  const handleScroll = (event, target) => {
    event.preventDefault();
    const headerHeight = headerRef.current.offsetHeight;
    const section = document.querySelector(target);
    
    if (section) {
      const targetPosition = section.getBoundingClientRect().top + window.scrollY - headerHeight;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
      });
    }
  };

  const handleScrollChange = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setBgColor('white');
    } else {
      setBgColor('black');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollChange);
    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, []);
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Fetch user's IP address
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        const userIpAddress = data.ip;  // IP address is available in data.ip
  
        // Prepare the form data, including the IP address
        const formDataWithIp = new FormData();
        formDataWithIp.append('name', formData.name);
        formDataWithIp.append('email', formData.email);
        formDataWithIp.append('message', formData.message);
        formDataWithIp.append('ip_address', userIpAddress);  // Add the IP address
  
        // Send data to Getform
        fetch('https://getform.io/f/blllzxyb', {
          method: 'POST',
          body: formDataWithIp,
        })
          .then((response) => {
            if (response.ok) {
              setSuccessMessage('Your message has been sent successfully!');
              setFormData({ name: '', email: '', message: '' });
            } else {
              setErrorMessage('Failed to send your message. Please try again later.');
            }
          })
          .catch((err) => {
            console.error('Error:', err);
            setErrorMessage('Failed to send your message. Please try again later.');
          });
      })
      .catch((error) => {
        console.error('Error fetching IP:', error);
        setErrorMessage('Failed to retrieve your IP address. Please try again later.');
      });
  };
  

  return (
    <div className="App">
       <Navbar ref={headerRef} style={{ backgroundColor: bgColor }} expand="lg" sticky="top" className="py-3">
        <Container>
          <Navbar.Brand href="#home"  style={{ color: bgColor === 'black' ? 'white' : 'black',display:'flex',flexDirection:'column',alignItems:'center' }}>HikeMedia<br/><span style={{fontSize:'10px',background:bgColor === 'black'?'#fff':'#000',padding:'5px',borderRadius:'25px',color:bgColor === 'black'?'#000':'#fff'}}>Media Partner</span></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#approach" onClick={(e) => handleScroll(e, '#approach')} style={{ color: bgColor === 'black' ? 'white' : 'black' }}>Our Approach</Nav.Link>
              <Nav.Link href="#service" onClick={(e) => handleScroll(e, '#service')} style={{ color: bgColor === 'black' ? 'white' : 'black' }}>Services</Nav.Link>
              <Nav.Link href="#whyus" onClick={(e) => handleScroll(e, '#whyus')} style={{ color: bgColor === 'black' ? 'white' : 'black' }}>Why Us?</Nav.Link>
              <Nav.Link href="#clients" onClick={(e) => handleScroll(e, '#clients')} style={{ color: bgColor === 'black' ? 'white' : 'black' }}>Our Clients</Nav.Link>
              <Nav.Link href="#contact" onClick={(e) => handleScroll(e, '#contact')} style={{ color: bgColor === 'black' ? 'white' : 'black' }}>Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="background-image">
        <Container fluid className="h-100 d-flex align-items-center justify-content-center">
          <Row>
            <Col className="text-overlay text-center">
              <h1>PROPELLING YOUR PERFORMANCE LEVITATING YOUR BRAND</h1>
              <p>At Hike Media, we ensure the best of digital marketing and performance marketing solutions for you.</p>
              <a href="#service" variant="outline-light" className='left_button header_btn'>Our Services</a>
              <a href="#contact" variant="outline-light" className=' header_btn'>Contact Us</a>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="my-5" id='approach'>
        <h2 className="text-center mb-5">Our Approach</h2>
        <Row>
          {approaches.map((item, index) => (
            <Col md={4} className="mb-4 text-center" key={index}>
              <Section customDelay={index}>
                <div className="icon mb-3" style={{ fontSize: '2rem' }}>
                  {item.icon}
                </div>
                <h4>{item.title}</h4>
                <p>{item.description}</p>
              </Section>
            </Col>
          ))}
        </Row>
      </Container>

      <div className="services-section text-center py-5" id="service">
        <h2 className="section-title">SCULPTING SUCCESS THROUGH TRUSTED ALLIANCES</h2>
        <p className="section-description mb-4">Our repertoire of services includes all things related to performance and affiliate marketing.</p>
        <Row>
          {services.map((service, index) => (
            <Col md={3} key={index} className="d-flex align-items-stretch">
              <Section customDelay={index}>
                <Card className="service-card mb-4">
                  <Card.Body className="d-flex flex-column">
                    <div className="icon-container mb-3">{service.icon}</div>
                    <Card.Title>{service.title}</Card.Title>
                    <Card.Text className="flex-grow-1">{service.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Section>
            </Col>
          ))}
        </Row>
      </div>

      <div className="why-us-section py-5" id="whyus">
        <Container>
          <Row className="align-items-center">
            <h2 className="section-title">Why Us?</h2>
            <h3 className="section-subtitle">360 Degree Digital Marketing Company</h3>
            <Col md={6}>
              <Accordion defaultActiveKey="0" flush>
                {steps.map((step, index) => (
                  <Section customDelay={index} key={index}>
                    <Accordion.Item eventKey={index.toString()}>
                      <Accordion.Header>
                        <span className="number text-[#ff0055]">{step.number}</span> {step.title}
                      </Accordion.Header>
                      <Accordion.Body>{step.description}</Accordion.Body>
                    </Accordion.Item>
                  </Section>
                ))}
              </Accordion>
            </Col>
            <Col md={6} className="d-none d-md-block">
              <Image src={BackImg} alt="Teamwork" fluid className="why-us-image" />
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="my-5 text-center">
        <Row>
          {steps.map((step, index) => (
            <Col key={index} md={3} className="mb-4">
              <Section customDelay={index}>
                <div className="step-number" style={{ fontSize: '48px', color: '#f05', fontWeight: 'bold' }}>
                  {step.number}
                </div>
                <h4 className="step-title">{step.title}</h4>
                <p className="step-description">{step.description}</p>
              </Section>
            </Col>
          ))}
        </Row>
      </Container>

      <Container className="text-center my-5" id="clients">
        <h2 className="mb-4">OUR CLIENTS</h2>
        <p className="mb-5">Checkout, who are our clients!</p>
        <Row>
          {clients.map((client, index) => (
            <Col key={index} xs={6} sm={4} md={3} className="mb-4">
              <Section customDelay={index}>
                <img src={client.logo} alt={client.name} className="img-fluid" style={{ maxHeight: '100px',maxWidth:'180px' }} />
              </Section>
            </Col>
          ))}
        </Row>
      </Container>
      <Container className="my-5" id="contact" style={{  padding: '2rem', borderRadius: '8px' }}>
  <h2 className="text-center mb-4 ">Contact Us</h2>
  <Row className="align-items-center">
    <Col md={6}>
      <img src={Contact} alt="Contact Us" className="img-fluid" />
    </Col>
    <Col md={6}>
      <Form onSubmit={handleSubmit}>
        {successMessage && <Alert variant="success">{successMessage}</Alert>}
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Form.Group controlId="formName">
          <Form.Label className="text-light">Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your name"
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label className="text-light">Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            required
          />
        </Form.Group>

        <Form.Group controlId="formMessage">
          <Form.Label className="text-light">Message</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows={4}
            placeholder="Your message"
            required
          />
        </Form.Group>

        <Button variant="light" type="submit" className="mt-3">
          Submit
        </Button>
      </Form>
    </Col>
  </Row>
</Container>


      <Container className="my-5 text-center" id="contact">
        <Row>
          {steps2.map((step, index) => (
            <Col key={index} md={3} className="mb-4">
              <Section customDelay={index}>
                <div className="step-number" style={{ fontSize: '48px', color: '#d3d3d3', fontWeight: 'bold' }}>
                  {step.number}
                </div>
                <h6 className="step-title">{step.title}</h6>
                <p className="step-description">{step.description}</p>
              </Section>
            </Col>
          ))}
        </Row>
      </Container>

      <footer className="bg-dark text-light py-3">
        <Container>
          <Row>
            <Col className="text-center">
              <p className="mb-0">&copy; {new Date().getFullYear()} Hike Media. All Rights Reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default App;
